$primary-color: #177000;
$secondary-color: yellow;
$secondary-color-hover: #66C361;
@mixin btn-color {
    background: rgb(106, 198, 90);
    background: -moz-linear-gradient(180deg, rgba(106, 198, 90, 1) 0%, rgba(80, 150, 68, 1) 76%, rgba(53, 99, 45, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(106, 198, 90, 1) 0%, rgba(80, 150, 68, 1) 76%, rgba(53, 99, 45, 1) 100%);
    background: linear-gradient(180deg, rgba(106, 198, 90, 1) 0%, rgba(80, 150, 68, 1) 76%, rgba(53, 99, 45, 1) 100%);
    color: white;
}

@mixin h1 {
    font-size: 50px;
    line-height: 65px;
    font-weight: 800;
}

@mixin h2 {
    font-size: 40px;
    line-height: 55px;
    font-weight: 700;
}

@mixin h3 {
    font-size: 30px;
    line-height: 45px;
    font-weight: 600;
}

@mixin h4 {
    font-size: 20px;
    line-height: 35px;
    font-weight: 500;
}

@mixin h5 {
    font-size: 10px;
    line-height: 25px;
    font-weight: 400;
}

@mixin h6 {
    font-size: 5px;
    line-height: 15px;
    font-weight: 300;
}

@mixin p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
}