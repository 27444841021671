@import "layouts/fonts";
@import "layouts/websites/variables";
.logo {
    background-color: #e0f0db;
}

.img-logo {
    width: 100%;
    position: relative;
}

.img-logo img {
    width: 50px;
}

.menu .list-menu {
    display: inline-block;
    margin-bottom: 0px;
}

.tagline {
    margin-top: 20px;
    width: 100%;
    display: none;
    color: #22226f;
}

.tagline .line1 {
    font-size: 8px;
}

.tagline .line2 {
    font-size: 8px;
}

.main-menu {
    height: 43px;
    background-color: $primary-color;
}

.menu {
    height: 43px;
    text-align: right;
    background-image: none;
    background-image: url(/images/bg-menu-mobile.png);
    background-repeat: repeat-y;
    background-attachment: fixed;
    background-position: center;
    background-size: 100% 100%;
}

.list-menu {
    display: inline-block;
    margin-bottom: 10px;
}

.list-menu li {
    list-style: none;
    display: inline-block;
    line-height: 40px;
}

.list-menu li a {
    display: inline-block;
    padding: 0 10px;
    font-size: 12px;
    line-height: 20px;
    color: $secondary-color;
}

.list-menu li a:hover {
    background-color: $secondary-color;
    color: black;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    text-decoration: none;
}

.list-menu li input {
    display: inline-block;
    padding: 0 10px;
    font-size: 10px;
    margin-right: 5px;
    border: 1px solid #ccc;
    color: white;
    margin-top: 10px;
    width: 100px;
    line-height: 17px;
}

.btn-icon {
    float: left;
    font-size: 20px;
    color: white;
    display: block;
    margin-top: 2px;
}

.btn-icon:hover {
    color: white;
}

.content h1 {
    @include h3;
    color: #007000;
    display: inline-block;
    border-bottom: solid #FBA900 3px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.content .banner-text .fas {
    font-size: 30px;
}

.content .banner-text h2 {
    font-weight: 800;
}

.content a.btn-green {
    @include btn-color;
    font-size: 22px;
    font-weight: 400;
    display: block;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.banner-img {
  background: rgb(106, 198, 90);
  background: -moz-linear-gradient(180deg, rgba(106, 198, 90, 1) 0%, rgb(80, 150, 68, 1) 76%, rgba(53, 99, 45, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(106, 198, 90, 1) 0%, rgb(80, 150, 68, 1) 76%, rgba(53, 99, 45, 1) 100%);
  background: linear-gradient(180deg, rgba(106, 198, 90, 1) 0%, rgba(80,150, 68, 1) 76%, rgba(53, 99, 45, 1) 100%);
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
  height: 330px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  img {
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    width: 100%;
    height: 100%;
    margin-left: -10px;
    margin-top: -10px;
  }
}

.dot {
    text-align: center;
}

.list-dot {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 0px;
    padding-top: 20px;
    display: inline-block;
}

.list-dot li {
    display: block;
    float: left;
    margin-right: 10px;
}

.list-dot li a {
    cursor: pointer;
    color: rgba(53, 99, 45, 1)!important;
}

.list-dot li a.active {
    color: rgb(106, 198, 90)!important;
}

.owl-dot span {
    cursor: pointer;
    background: rgba(53, 99, 45, 1)!important;
}

.owl-dot.active span {
    background: rgb(106, 198, 90)!important;
}


/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
    .tagline {
        margin-top: 15px;
    }
    .tagline .line1 {
        font-size: 10px;
    }
    .tagline .line2 {
        font-size: 10px;
    }
}

.news {
    margin-top: 0px;
}

.card-view img {
    -webkit-border-top-left-radius: 30px;
    -webkit-border-top-right-radius: 30px;
    -moz-border-radius-topleft: 30px;
    -moz-border-radius-topright: 30px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.card-view .box {
    border: #ccc solid 1px;
    background-color: white;
    display: inline-block;
    width: 100%;
    padding: 15px 20px 25px 20px;
    -webkit-border-bottom-right-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-bottomright: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}

.card-view .box .date {
    font-size: 15px;
    color: #ccc;
}

.p-text {
    margin: 15px 0;
    font-size: 17px;
    line-height: 30px;
    font-weight: 200;
    padding-bottom: 20px;
    margin: 0px;
}

.section-white {
    background-color: white;
    display: inline-block;
    width: 100%;
}

#map, .map {
    width: 100%;
    height: 450px;
}

.contact-img {
    float: right;
    display: block;
}

.footer-section {
    display: inline-block;
    width: 100%;
    background-color: #004236;
}

.footer-section .list-menu {
    margin-bottom: 0px;
}

.footer-section .list-menu li {
    line-height: 30px;
}

.box {
    .collapse:not(.show) {
        height: 42px !important;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
    }
    
    .collapsing {
        min-height: 42px !important;
    }

    .btn-expand {
        display: block;
        margin: .5rem 0;
        background: transparent;
        box-shadow: none;
        border: none;
        padding: 0;
        color: #0000ee;
        font-size: 14px;

        &.collapsed:after  {
            content: 'เพิ่มเติม...';
        }
        
        &:not(.collapsed):after {
            content: 'น้อยลง...';
        }
    }
}


/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    .content .banner-text .fas {
        font-size: 60px;
    }

    .btn-icon {
        display: none;
    }
    .menu {
        background-image: url(/images/bg-menu.png);
        background-size: 100% 100%;
    }
    .img-logo {
        width: 800px;
        position: absolute;
        left: 20px;
    }
    .img-logo img {
        width: 100%;
    }
    .img-logo {
        width: 100px;
    }
    .tagline {
        margin-top: 30px;
        display: inline-block;
    }
    .tagline .line1 {
        font-size: 15px;
    }
    .tagline .line2 {
        font-size: 15px;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .img-logo img {
        width: 100%;
        left: 55px;
        margin-top: 12px;
    }
    .tagline {
        margin-top: 30px;
        width: 100%;
    }
    .tagline .line1 {
        font-size: 22px;
    }
    .tagline .line2 {
        font-size: 22px;
    }
}


/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .tagline {
        margin-top: 30px;
        width: 100%;
    }
    .tagline .line1 {
        font-size: 25px;
    }
    .tagline .line2 {
        font-size: 25px;
    }
}
